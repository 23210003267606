



.confirmation-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff; /* white background overlay */
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .confirmation-loading-overlay span {
    margin-top: 10px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
  
  /* Spinner design */
  .confirmation-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* light gray */
    border-top: 5px solid #d4454f; /* updated accent color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Keyframes for spinner rotation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }